@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'classes';

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter';
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: local('Inter'), url('./fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: local('Inter'), url('./fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: local('Inter'), url('./fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: local('Inter'), url('./fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url('./fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'), url('./fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pangolin';
  font-weight: 400;
  src: local('Pangolin'), url('./fonts/Pangolin-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: $fontSizePrimary;
  color: $colorPrimary;
  font-family: $fontPrimary !important;
  font-weight: 500;
  margin: auto auto;
}

.-hmru-dialog-title {
  font-family: 'Roboto', sans-serif;
}

.-hmru-dialog-body {
  border-radius: 14px !important;

  .-hmru-dialog-content {
    padding: 0 15px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  .-hmru-dialog-footer {
    button {
      font-size: 14px !important;
    }
  }
}

section.section-bg {
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.section-container {
  padding: 20px;
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
